<template>
  <md-card>
    <md-card-header :data-background-color="getTheme">
      <h4 class="title">
        {{
          editMode
            ? $t("workspace.geofence.geofenceForm.titleFormEdit")
            : $t("workspace.geofence.geofenceForm.titleFormCreate")
        }}
      </h4>
    </md-card-header>

    <md-card-content>
      <div class="container-fluid">
        <v-form ref="form">
          <div class="row">
            <div class="col-12 col-lg-5">
              <div class="col-12">
                <div class="row d-flex align-center m-0 mb-5">
                  <div class="col col-10">
                    <TGCTextField
                      :label="$t('workspace.geofence.geofenceForm.name')"
                      v-model="form.name"
                      :rules="[
                        rules.required,
                        (value) => rules.minLength(value, 0),
                        (value) => rules.maxLength(value, 100),
                      ]"
                    />
                  </div>
                  <v-tooltip bottom open-delay="500">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        size="20"
                        class="col col-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-information-outline</v-icon
                      >
                    </template>
                    <span>{{ $t("tooltips.geofenceForm.name") }}</span>
                  </v-tooltip>
                </div>
              </div>
              <div class="col-12">
                <div class="row d-flex align-center m-0 mb-5">
                  <div class="col col-10">
                    <v-textarea
                      :label="$t('workspace.geofence.geofenceForm.description')"
                      v-model="form.description"
                    />
                  </div>
                  <v-tooltip bottom open-delay="500">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        size="20"
                        class="col col-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-information-outline</v-icon
                      >
                    </template>
                    <span>{{ $t("tooltips.geofenceForm.description") }}</span>
                  </v-tooltip>
                </div>
              </div>
              <div class="col-12">
                <div class="row d-flex align-center m-0 mb-5">
                  <div class="col col-10">
                    <v-color-picker
                      dot-size="10"
                      mode="hexa"
                      swatches-max-height="100"
                      v-model="form.color"
                    ></v-color-picker>
                  </div>
                  <v-tooltip bottom open-delay="500">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        size="20"
                        class="col col-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-information-outline</v-icon
                      >
                    </template>
                    <span>{{ $t("tooltips.geofenceForm.color") }}</span>
                  </v-tooltip>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-7">
              <zone-map
                :color="form.color"
                @geofence="setGeofence($event)"
                :geofenceCenter="center"
                :geofencePoints="geofence"
              ></zone-map>
            </div>
          </div>
        </v-form>
      </div>
      <div class="md-layout">
        <div class="md-layout-item md-size-100 text-right">
          <tgl-save-button
            ref="saveBtn"
            :entity="entity"
            :dispatch="entity.dispatch"
            :action="editMode ? 'editado' : 'creado'"
            :redirect="entity.redirect"
            :validateForm="handleSubmit"
          ></tgl-save-button>
        </div>
      </div>
    </md-card-content>
  </md-card>
</template>
<script>
import ZoneMap from "@/components/Maps/ZoneMap";
import form from "@/mixins/form";
import { mapGetters } from "vuex";
import TglSaveButton from "@/components/UI/TglSaveButton.vue";
import TGCTextField from "@/components/UI/TGCTextField";
export default {
  name: "geofence-form",
  mixins: [form],
  props: {
    dataBackgroundColor: {
      type: String,
      default: "",
    },
  },
  components: {
    ZoneMap,
    TglSaveButton,
    TGCTextField,
  },
  data() {
    return {
      form: {
        name: "",
        color: "#23c577",
        description: "",
      },
      geofence: [],
      center: [],
      editMode: false,
    };
  },
  watch: {
    "form.lat"() {
      this.latLng = [this.form.lat, this.form.lng];
    },
    "form.lng"() {
      this.latLng = [this.form.lat, this.form.lng];
    },
    hasInitialPosition() {
      this.form.lat = -33.4513;
      this.form.lng = -70.6653;
    },
  },
  computed: {
    ...mapGetters(["getTheme"]),
    entity() {
      let params = {
        name: this.form.name,
        description: this.form.description,
        color: this.form.color,
        geofence: this.geofence,
      };
      return {
        name: "geocerca",
        params: this.editMode
          ? { ...params, id: this.$route.params.geofence }
          : params,
        dispatch: this.editMode ? "zone/editZone" : "zone/createZone",
        redirect: { name: "workspace", params: { tab: 1 } },
      };
    },
  },
  mounted: function () {
    if (this.$route.params.geofence && this.$route.params.tab == "edit") {
      this.editMode = true;
      this.getZone();
    }
  },
  methods: {
    handleSubmit() {
      return this.$refs.form.validate();
    },
    getZone() {
      this.$store
        .dispatch("zone/getZone", this.$route.params.geofence)
        .then((data) => {
          this.geofence = data.geofence;
          this.form.name = data.name;
          this.form.description = data.description;
          this.form.color = data.color;
          this.center = [data.geofence[0][0], data.geofence[0][1]];
        })
        .catch((err) => console.error(err));
    },
    geo_success(position) {
      this.form.lat = position.coords.latitude;
      this.form.lng = position.coords.longitude;
    },
    geo_error() {
      alert("Sorry, no position available.");
    },
    userLocation() {
      navigator.geolocation.getCurrentPosition(
        this.geo_success,
        this.geo_error
      );
    },
    updateLatLng(e) {
      (this.form.lat = e[0]), (this.form.lng = e[1]);
    },
    setGeofence(geofence) {
      this.geofence = geofence;
    },
  },
};
</script>