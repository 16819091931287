<template>
  <l-map
    :zoom="zoom"
    :center="center"
    :options="mapOptions"
    style="height: 500px"
    @update:center="centerUpdate"
    @update:zoom="zoomUpdate"
    ref="map"
    @dblclick="addMarkerOnClick"
  >
    <l-tile-layer :url="url" :attribution="attribution" />
    <l-control position="topright">
      <v-btn fab x-small @click="removeMarker" :retain-focus-on-click="false">
        <v-tooltip bottom open-delay="500">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="primary" v-bind="attrs" v-on="on">
              mdi-minus-circle-outline</v-icon
            >
          </template>
          <span>{{ $t("tooltips.geoFenceControlDelete") }}</span>
        </v-tooltip>
      </v-btn>
    </l-control>
    <l-control position="topright">
      <v-btn
        fab
        x-small
        @click="visibleGeofence"
        :retain-focus-on-click="false"
      >
        <v-tooltip bottom open-delay="500">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="primary" v-bind="attrs" v-on="on">
              mdi-shape-polygon-plus</v-icon
            >
          </template>
          <span>{{ $t("tooltips.geoFenceControlShow") }}</span>
        </v-tooltip>
      </v-btn>
    </l-control>
    <l-control position="topright">
      <v-btn fab x-small @click="clearMarkers" :retain-focus-on-click="false">
        <v-tooltip bottom open-delay="500">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="primary" v-bind="attrs" v-on="on">
              mdi-autorenew</v-icon
            >
          </template>
          <span>{{ $t("tooltips.geoFenceControlRemove") }}</span>
        </v-tooltip>
      </v-btn>
    </l-control>
    <l-marker
      v-for="(marker, index) in markers"
      :key="index"
      :lat-lng.sync="marker.latLng"
      :draggable="true"
      :visible="marker.visible"
      @update:latLng="updateMarker(index, $event)"
      @dblclick="removeMarkerOnClick(index)"
    >
      <l-icon :iconSize="[20, 20]" :iconAnchor="[10, 10]">
        <icon-base :height="20" :width="20" :geoFenceMarker="true">
          <geo-fence-icon-marker></geo-fence-icon-marker>
        </icon-base>
      </l-icon>
      <l-tooltip
        :options="{
          direction: 'top',
        }"
      >
        {{ index }}</l-tooltip
      >
    </l-marker>
    <l-polygon
      v-if="geofence.length > 3 && showGeofence"
      :lat-lngs="geofence"
      :weight="2"
      :opacity="1"
      :color="color"
      :fillColor="color"
    ></l-polygon>
    <!-- te amo -->
  </l-map>
</template>
<script>
import L from "leaflet";
import "leaflet-search/src/leaflet-search";
import {
  LMap,
  LTileLayer,
  LMarker,
  // LCircleMarker,
  LIcon,
  // LLayerGroup,
  LTooltip,
  LControl,
  LPolygon,
} from "vue2-leaflet";
import { Icon } from "leaflet";
import IconBase from "@/components/Icons/IconBase";
import GeoFenceIconMarker from "@/components/Icons/GeoFenceIconMarker";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  name: "zone-map",
  props: {
    color: {
      type: String,
      required: true,
    },
    geofenceCenter: {
      type: Array,
      required: true,
    },
    geofencePoints: {
      type: Array,
    },
  },
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LTooltip,
    LControl,
    LPolygon,
    IconBase,
    GeoFenceIconMarker,
  },
  data: () => ({
    zoom: 12,
    center: [-33.4513, -70.6653],
    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    attribution:
      '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    currentZoom: 11.5,
    currentCenter: [-33.4513, -70.6653],
    mapOptions: {
      zoomSnap: 0.5,
      doubleClickZoom: false,
    },
    markers: [
      { latLng: { lat: -33.4513, lng: -70.6653 }, visible: true },
      { latLng: { lat: -33.4513, lng: -70.6653 }, visible: false },
    ],
    showGeofence: false,
    geofence: [],
  }),
  watch: {
    geofenceCenter() {
      this.center = this.geofenceCenter;
    },
    geofencePoints() {
      this.setMarkers();
    },
  },
  methods: {
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    targetMarker() {
      this.latLng = [this.currentCenter.lat, this.currentCenter.lng];
      this.center = this.latLng;
    },
    addMarkerOnClick(e) {
      this.geofence = [];
      let lat = e.latlng.lat;
      let lng = e.latlng.lng;

      this.markers.splice(this.markers.length - 1, 0, {
        latLng: [lat, lng],
        visible: true,
      });
    },
    removeMarker() {
      this.geofence = [];
      if (this.markers.length > 2) {
        this.markers.pop();
        this.markers.pop();
        this.markers.push({ latLng: this.markers[0].latLng, visible: false });
      }
    },
    removeMarkerOnClick(index) {
      this.geofence = [];
      this.markers.splice(index, 1);
    },
    updateMarker(i, e) {
      this.markers[i].latLng.lat = e.lat;
      this.markers[i].latLng.lng = e.lng;
      if (i == 0) {
        this.markers[this.markers.length - 1].latLng.lat = e.lat;
        this.markers[this.markers.length - 1].latLng.lng = e.lng;
      }
      if (this.markers.length > 3) {
        this.updateGeofence();
      }
    },
    clearMarkers() {
      (this.geofence = []), (this.showGeofence = false);

      this.markers = [
        {
          latLng: { lat: this.currentCenter.lat, lng: this.currentCenter.lng },
          visible: true,
        },
        {
          latLng: { lat: this.currentCenter.lat, lng: this.currentCenter.lng },
          visible: false,
        },
      ];
    },
    updateGeofence() {
      this.geofence = [];
      this.showGeofence = false;
      this.markers.forEach((marker) => {
        this.geofence.push([marker.latLng.lat, marker.latLng.lng]);
      });
      this.showGeofence = true;
      this.$emit("geofence", this.geofence);
    },
    visibleGeofence() {
      this.showGeofence = !this.showGeofence;
    },
    setMarkers() {
      this.markers = [];
      this.geofencePoints.forEach((point) => {
        this.markers.push({
          latLng: { lat: point[0], lng: point[1] },
          visible: true,
        });
      });
      this.markers[this.markers.length - 1].visible = false;
      this.geofence = this.geofencePoints;
    },
  },
  mounted() {
    this.latLng = this.parentLatLng;
    const map = this.$refs.map.mapObject;
    const geoSearch = new L.Control.Search({
      url: "https://nominatim.openstreetmap.org/search?format=json&q={s}",
      jsonpParam: "json_callback",
      propertyName: "display_name",
      propertyLoc: ["lat", "lon"],
      autoCollapse: true,
      marker: false,
      autoType: false,
      minLength: 2,
    });

    geoSearch.on("search:locationfound", (response) => {
      this.latLng = [response.latlng.lat, response.latlng.lng];
    });

    map.addControl(geoSearch);

    let popup = L.popup({
      className: "instruction-popup",
    })
      .setLatLng([-33.4513, -70.6653])
      .setContent(
        `- Doble click en el mapa para agregar un marcador nuevo. </br>
      - Doble click en marcador para eliminar el marcador del mapa. </br>`
      )
      .openOn(map);
    map.openPopup(popup);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>
<style lang="sass">
@import '@/assets/sass/partials/map.sass'
</style>
